import React, { FunctionComponent } from 'react';
import { ForgePaginator } from '@tylertech/forge-react';
import { IPaginatorChangeEvent } from '@tylertech/forge';
import I18n from 'common/i18n';
import { fetchAssetsByParameters, getResultsTotalSize } from '../store/AssetSlice';
import { useSelector } from 'react-redux';
import { getPageNumber, getLimit, updatePageNumberInUrl, updatePageSizeInUrl } from '../store/UrlSlice';
import { useAppDispatch } from '../store/hooks';
import { DEFAULT_PAGE_SIZE_OPTIONS } from '../types';

const ResultsPaginator: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const total = useSelector(getResultsTotalSize);
  let index = useSelector(getPageNumber);
  index = index ? index - 1 : 0;
  const limit = useSelector(getLimit);
  const dispatch = useAppDispatch();

  const onChangePage = (e: { detail: IPaginatorChangeEvent }) => {
    if (e.detail.type === 'page-size') {
      dispatch(updatePageSizeInUrl(e.detail.pageSize));
    }
    dispatch(updatePageNumberInUrl(e.detail.pageIndex + 1));
    dispatch(fetchAssetsByParameters());
  };

  return (
    <ForgePaginator
      data-testid="asset-results-paginator"
      firstLast
      label={I18n.t('results_per_page', { scope })}
      pageIndex={index}
      pageSize={limit}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      total={total}
      on-forge-paginator-change={onChangePage}
    />
  );
};

export default ResultsPaginator;
