import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentDomain } from 'common/currentDomain';
import { fetchConfigsByType } from 'common/core/configurations';
import { RootState } from 'browse3/store/store';
import { CatalogLandingPageConfig } from 'browse3/types';

export interface CatalogLandingPageConfigState {
  results: CatalogLandingPageConfig[];
}

const initialState: CatalogLandingPageConfigState = {
  results: []
};

export const fetchCatalogLandingPageConfig = createAsyncThunk(
  'catalog/getCatalogLandingPageConfig',
  async (thunkAPI) => {
    const response = await fetchConfigsByType(getCurrentDomain(), 'catalog_landing_page');
    return response;
  }
);

export const CatalogLandingPageConfigSlice = createSlice({
  name: 'catalogLandingPageConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCatalogLandingPageConfig.fulfilled, (state, action) => {
      // there should never be more than one result with default==true, so we break when we find the first one
      // but we need to loop because the response is an array of config objects
      const configResult = action.payload.find((configEntry: any) => configEntry.default);
      if (configResult) {
        configResult.properties.map((property: any) => {
          const clpConfig: CatalogLandingPageConfig = {
            name: property.name,
            headline: property.value.headline || '',
            description: property.value.description || ''
          };
          state.results.push(clpConfig);
        });
      } else {
        state.results = [];
      }
    });
  }
});

export const getCatalogLandingPageConfig = (state: RootState) => {
  return state.catalogLandingPageConfig;
};

export default CatalogLandingPageConfigSlice.reducer;
