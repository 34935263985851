import React, { FunctionComponent, useEffect, useState } from 'react';
import { ForgeAutocomplete, ForgeIconButton, ForgeTextField } from '@tylertech/forge-react';
import { IAutocompleteComponent, IAutocompleteOption } from '@tylertech/forge';
import { ForgeIcon } from '@tylertech/forge-react';
import { useSelector } from 'react-redux';
import { CatalogAutocompleteResult } from '../browse3/types';
import ceteraUtils from 'common/cetera/utils';
import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import { createNewTextSearchInUrl, getTextQuery } from '../browse3/store/UrlSlice';
import { fetchAssetsByParameters } from '../browse3/store/AssetSlice';
import { useAppDispatch } from '../browse3/store/hooks';
import { getParamValueInUrl } from 'accessibleBrowseFilters/helpers';

const AssetSearchAutocomplete: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<string>('');
  const searchStringInUrl = useSelector(getTextQuery);

  useEffect(() => {
    const queryInUrl = getParamValueInUrl('q');
    if (queryInUrl === null) {
      setInputValue('');
    } else {
      setInputValue(queryInUrl);
    }
  }, [searchStringInUrl]);

  const fetchAssetListForAutocomplete = (assetSearch: string): Promise<IAutocompleteOption<string>[]> => {
    if (assetSearch === '') {
      return Promise.resolve([]);
    }

    return ceteraUtils
      .autocompleteQuery(assetSearch)
      .then((response: any) => {
        return response.results;
      })
      .then((assetResults: CatalogAutocompleteResult[]) => {
        const resultTitles: IAutocompleteOption<string>[] = [];
        assetResults.map((res) => {
          const newResult: IAutocompleteOption<string> = {
            label: res.title,
            value: res.title // value not used, but must be assigned
          };
          resultTitles.push(newResult);
        });
        return resultTitles;
      })
      .catch((err: any) => {
        airbrake.notify({
          err,
          context: { component: 'AssetSearchAutocomplete' }
        });
      });
  };

  const onAssetSelect = (searchString: string) => {
    dispatch(createNewTextSearchInUrl(searchString));
    dispatch(fetchAssetsByParameters());
  };

  const inputOnKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      dispatch(createNewTextSearchInUrl(evt.currentTarget.value));
      dispatch(fetchAssetsByParameters());
      const search = document.getElementById('asset-search-autocomplete') as IAutocompleteComponent | null;
      if (search) {
        search.closeDropdown();
      }
    }
  };

  return (
    <div className="search-autocomplete">
      <ForgeAutocomplete
        filter={fetchAssetListForAutocomplete}
        on-forge-autocomplete-select={(event: CustomEvent) => {
          onAssetSelect(event.detail.value);
        }}
        filterOnFocus={false}
        filterFocusFirst={false}
        allowUnmatched={true}
        value={inputValue}
        id="asset-search-autocomplete"
        mode="stateless"
      >
        <ForgeTextField shape={'rounded'}>
          <ForgeIcon slot="leading" name="search" />
          <input
            placeholder={I18n.t('search_catalog', { scope })}
            data-testid="asset-search-autocomplete-input"
            type="text"
            className="asset-search-input"
            onKeyUp={inputOnKeyUp}
          />
          <ForgeIconButton data-forge-autocomplete-clear slot="trailing" dense density="3">
            <button type="button" aria-label={I18n.t('search_clear', { scope })}>
              <ForgeIcon name="close"></ForgeIcon>
            </button>
          </ForgeIconButton>
        </ForgeTextField>
      </ForgeAutocomplete>
    </div>
  );
};

export default AssetSearchAutocomplete;
