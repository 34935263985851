import React, { FunctionComponent } from 'react';
import { ForgeSelect } from '@tylertech/forge-react';
import { IOption } from '@tylertech/forge';
import I18n from 'common/i18n';
import { fetchAssetsByParameters } from '../store/AssetSlice';
import { updateSortByAndResetPageInUrl, getOrder } from '../store/UrlSlice';
import { useAppDispatch } from '../store/hooks';
import { useSelector } from 'react-redux';

const SortResults: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const sortByDefaultValue = useSelector(getOrder);
  const dispatch = useAppDispatch();

  const sortOptions: IOption[] = [
    { label: I18n.t('sort.A_to_Z', { scope }), value: 'name' },
    { label: I18n.t('sort.most_relevant', { scope }), value: 'relevance' },
    { label: I18n.t('sort.most_viewed', { scope }), value: 'page_views_total' },
    { label: I18n.t('sort.recently_added', { scope }), value: 'createdAt' },
    { label: I18n.t('sort.recently_updated', { scope }), value: 'updatedAt' }
  ];
  const sortProps = {
    'data-testid': 'asset-results-sort-by-select',
    floatLabelType: 'always',
    label: I18n.t('sort.label', { scope }),
    onChange: (e: CustomEvent) => onChangeSorting(e.detail),
    options: sortOptions,
    value: sortByDefaultValue
  };

  const onChangeSorting = (order: string) => {
    dispatch(updateSortByAndResetPageInUrl(order));
    dispatch(fetchAssetsByParameters());
  };

  return <ForgeSelect {...sortProps} />;
};

export default SortResults;
