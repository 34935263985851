import React, { FunctionComponent } from 'react';
import { ForgeButton, ForgePageState } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import { removeAllFiltersAndTagsFromUrl } from '../store/UrlSlice';
import { fetchAssetsByParameters } from '../store/AssetSlice';
import { useAppDispatch } from '../store/hooks';

const NoResultsPage: FunctionComponent = () => {
  const scope = 'controls.browse.browse3';
  const dispatch = useAppDispatch();

  const onClearAllFilters = () => {
    dispatch(removeAllFiltersAndTagsFromUrl());
    dispatch(fetchAssetsByParameters());
  };

  return (
    <ForgePageState className="no-results-found">
      <img
        src="https://cdn.forge.tylertech.com/v1/images/spot-hero/general-empty-state-spot-hero.svg"
        slot="graphic"
        alt=""
      />
      <div slot="title">{I18n.t('no_results', { scope })}</div>
      <p slot="message">{I18n.t('no_results_subtitle', { scope })}</p>
      <ForgeButton type="raised" slot="action">
        <button data-testid="No-results-clear-all-filters-button" onClick={onClearAllFilters}>
          {I18n.t('clear_all_filters', { scope })}
        </button>
      </ForgeButton>
    </ForgePageState>
  );
};

export default NoResultsPage;
