import { Filter, FilterOption } from './types';
import I18n from 'common/i18n';

export const isSearchParamInUrl = (param: string, value: string): boolean => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  const results = searchParams.getAll(param); // use getAll with query param name, and then check results for actual param value
  return results.includes(value);
};

export const getParamValueInUrl = (param: string): string | null => {
  const currentURL: string = window.location.href;
  const searchParams = new URL(currentURL).searchParams;
  return searchParams.get(param);
};

export const alphabetizeFilters = (filters: Filter[]): Filter[] => {
  // loop through filters, sorting each of their options alphabetically
  filters.forEach((f) => {
    f.options.sort(function (filter1, filter2) {
      // I don't know if these will always be the same case, so make everything lowercase to compare
      const option1: string = filter1.text.toLowerCase();
      const option2: string = filter2.text.toLowerCase();
      if (option1 < option2) {
        return -1;
      } else if (option1 > option2) {
        return 1;
      } else {
        return 0;
      }
    });
  });

  return filters;
};

/**
 * Some of the category options have children. We will display children that are one level deep.
 * To do this, we loop through each option in categories and build up a new filter containing
 * options for each of the options and their first  level children.
 * @param filterFacets All filters on the page
 * @returns
 */
export const restructureCategories = (filterFacets: Filter[]): Filter[] => {
  let categoryFilter: Filter;
  const isCategory = (filter: Filter) => filter.param === 'category';
  const index = filterFacets.findIndex(isCategory);

  if (index != -1) {
    categoryFilter = filterFacets[index];

    const newCategoryFilter: Filter = {
      param: categoryFilter.param,
      title: categoryFilter.title,
      options: []
    };

    categoryFilter.options.map((option) => {
      newCategoryFilter.options.push(option);
      if (option.children && option.children.length > 0) {
        option.children.map((c) => {
          const newOption: FilterOption = {
            text: c.text,
            value: c.value
          };
          newCategoryFilter.options.push(newOption);
        });
      }
    });

    if (categoryFilter.extra_options) {
      categoryFilter.extra_options.map((option) => {
        newCategoryFilter.options.push(option);
        if (option.children && option.children.length > 0) {
          option.children.map((c) => {
            const newOption: FilterOption = {
              text: c.text,
              value: c.value
            };
            newCategoryFilter.options.push(newOption);
          });
        }
      });
    }

    filterFacets[index] = newCategoryFilter;
  }

  return filterFacets;
};

// The purpose of this method is to either a) deselect a selected filter or
// b) select a new filter and delete the existing one. This is all done through
// manipulation of the query string in the URL.
export const changeUrlBasedOnFilterParamsAndReload = (filterParam: string, currentFilter: string) => {
  const currentURL: string = window.location.href;
  const url = new URL(currentURL);

  if (url.searchParams.get(filterParam) === currentFilter) {
    // when de-selecting a filterOption
    url.searchParams.delete(filterParam);
  } else {
    url.searchParams.delete(filterParam); // For swapping filterOptions and for new values of filterOptions
    url.searchParams.append(filterParam, currentFilter);
  }
  window.location.href = url.href;
};

// Use this method when we know all we're doing is removing existing filters
export const removeQueryStringFromUrlAndReload = (filterParam: string) => {
  const currentURL: string = window.location.href;
  const url = new URL(currentURL);
  if (url.searchParams.get(filterParam)) {
    url.searchParams.delete(filterParam);
  }
  window.location.href = url.href;
};

export const generateProvenanceFilter = () => {
  const scope = 'controls.browse.browse3.filter';

  const officialFilterOption: FilterOption = {
    text: I18n.t('authority.official', { scope }),
    value: 'official'
  };

  const communityFilterOption: FilterOption = {
    text: I18n.t('authority.community', { scope }),
    value: 'community'
  };

  const authorityFilter: Filter = {
    title: I18n.t('authority.title', { scope }),
    param: 'provenance',
    options: [officialFilterOption, communityFilterOption]
  };

  return authorityFilter;
};
