import { configureStore } from '@reduxjs/toolkit';
import { AssetSlice } from './AssetSlice';
import { BrowseConfigSlice } from './BrowseConfigSlice';
import { CatalogConfigSlice } from './CatalogConfigSlice';
import { UrlSlice } from './UrlSlice';
import { FeaturedContentSlice } from 'browse3/store/FeaturedContentSlice';
import { CatalogLandingPageConfigSlice } from 'browse3/store/CatalogLandingPageConfigSlice';

export const store = configureStore({
  reducer: {
    assets: AssetSlice.reducer,
    browseConfig: BrowseConfigSlice.reducer,
    catalogConfig: CatalogConfigSlice.reducer,
    catalogLandingPageConfig: CatalogLandingPageConfigSlice.reducer,
    featuredContent: FeaturedContentSlice.reducer,
    url: UrlSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
