import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import airbrake from 'common/airbrake';
import { store } from './store/store';
import Browse3App from './Browse3App';

airbrake.init(window.serverConfig.airbrakeProjectId, window.serverConfig.airbrakeKey);

ReactDOM.render(
    <Provider store={store}>
        <Browse3App />
    </Provider>
    ,
    document.querySelector('#browse3')
);
